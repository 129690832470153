import styled from 'styled-components';

export const Iframe = styled.iframe`
  min-width: 100%;
  border: none;
  overflow: hidden;
  height: 1027px;
`;

export const Body = styled.div`
  padding: 0px 110px 0px;
  @media (max-width: 1199px) {
    padding: 0px 50px 20px 50px;
  }
  @media (max-width: 767px) {
    padding: 0px 35px 0 35px;
  }
`;
